var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$lang("users.permissionsBrowser.title"))+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-autocomplete',{attrs:{"label":_vm.$lang('users.application'),"items":_vm.applications,"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.application),callback:function ($$v) {_vm.application=$$v},expression:"application"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$lang('users.search'),"disabled":!_vm.application,"outlined":"","dense":"","hide-details":"auto"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.permissions,"items-per-page":25,"footer-props":{
          itemsPerPageOptions: [25, 50, 100],
        },"search":_vm.search,"loading":_vm.isPermissionsLoading},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
              name: 'user',
              params: {
                user: item.username,
              },
              query: _vm.query,
            }}},[_vm._v(_vm._s(item.fullName))])]}},{key:"item.appSections",fn:function(ref){
            var item = ref.item;
return [_c('PermissionByAppList',{attrs:{"value":item,"permission-entities":[
              _vm.PermissionEntity.APP_SECTION,
              _vm.PermissionEntity.APP_SECTIONS ]}})]}},{key:"item.adNetworks",fn:function(ref){
            var item = ref.item;
return [_c('PermissionByAppList',{attrs:{"value":item,"permission-entities":[
              _vm.PermissionEntity.AD_NETWORK,
              _vm.PermissionEntity.AD_NETWORKS ]}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }