
































import { uniq } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";

import {
  PermissionEntity,
  UserPermissionsByApplicationModel,
} from "@/iam/models/PermissionModel";
import { ReportType } from "@/reports/models";
import ReportUtil from "@/reports/utils/ReportUtil";

@Component
export default class PermissionByAppList extends Vue {
  @Prop() value!: UserPermissionsByApplicationModel;
  @Prop() permissionEntities!: Array<PermissionEntity>;

  readonly VISIBLE_COUNT = 5;
  isReducedView = true;

  get entities(): Array<string> {
    return uniq(
      this.value.simplifiedPermissions.flatMap(
        ({ entity, isAggregator, value }) =>
          this.permissionEntities.includes(entity) && !isAggregator
            ? [this.getEntityName(entity, value)]
            : []
      )
    ).sort((entityA, entityB) => {
      if (entityA.toLowerCase() === entityB.toLowerCase()) {
        return 0;
      }

      return entityA.toLowerCase() > entityB.toLowerCase() ? 1 : -1;
    });
  }

  get visibleEntities(): Array<string> {
    return this.entities.slice(
      0,
      this.isReducedView ? this.VISIBLE_COUNT : undefined
    );
  }

  get entitiesValue(): string {
    return this.entities
      .slice(0, this.isReducedView ? this.VISIBLE_COUNT : undefined)
      .join(", ");
  }

  get aggregators(): Array<string> {
    return uniq(
      this.value.simplifiedPermissions.flatMap(
        ({ entity, isAggregator, value }) =>
          this.permissionEntities.includes(entity) && isAggregator
            ? [value]
            : []
      )
    );
  }

  getEntityName(entity: PermissionEntity, value: string): string {
    if (entity !== PermissionEntity.APP_SECTION) {
      return value;
    }

    return value in ReportType
      ? ReportUtil.getReportNameByReportType(value as ReportType)
      : this.$lang(`users.appSection.${value.toLowerCase()}`);
  }
}
