











































































import { Component, Vue, Watch } from "vue-property-decorator";

import PermissionByAppList from "@/iam/components/PermissionByAppList.vue";

import {
  PermissionAggregatorNames,
  PermissionEntity,
  PermissionEntityNames,
  PermissionLink,
  UserPermissionsByApplicationModel,
} from "@/iam/models/PermissionModel";

@Component({ components: { PermissionByAppList } })
export default class PermissionsBrowserView extends Vue {
  readonly PermissionEntity = PermissionEntity;
  readonly headers = [
    {
      text: this.$lang("users.permissionsBrowser.user"),
      value: "fullName",
      width: "14%",
    },
    {
      text: this.$lang("users.permissionsBrowser.appSections"),
      value: "appSections",
      sortable: false,
      width: "50%",
    },
    {
      text: this.$lang("users.permissionsBrowser.adNetworks"),
      value: "adNetworks",
      sortable: false,
      width: "36%",
    },
  ];

  application: PermissionLink | null = null;
  search = "";

  get isPermissionsLoading(): boolean {
    return this.$store.state.usersStore.isPermissionsLoading;
  }

  get permissions(): Array<UserPermissionsByApplicationModel> {
    return this.$store.state.usersStore.permissions;
  }

  get applicationItems(): Array<Record<string, any>> {
    return this.$store.state.usersStore.permissionEntityNames
      .find(
        ({ ngacEntityName }: PermissionEntityNames) =>
          ngacEntityName === PermissionEntity.APPLICATION
      )
      .ngacNames.map((value: string) => ({
        value: {
          ngacName: value,
          ngacEntityName: PermissionEntity.APPLICATION,
        },
        text: this.$store.getters.applicationsById.get(value)?.name || value,
      }));
  }

  get applications(): Array<Record<string, any>> {
    const aggregators: Array<string> =
      (
        this.$store.state.usersStore
          .aggregatorNames as Array<PermissionAggregatorNames>
      )
        .find(
          ({ ngacEntityName }: PermissionAggregatorNames) =>
            ngacEntityName === PermissionEntity.APPLICATIONS
        )
        ?.aggregators.map(({ name }) => name) || [];

    if (!aggregators.length) {
      return this.applicationItems;
    }

    return [
      { header: this.$lang("users.applications") },
      ...this.applicationItems,
      { divider: true },
      {
        header: this.$lang("users.applicationAggregators"),
      },
      ...aggregators.map((value) => ({
        value: {
          ngacName: value,
          ngacEntityName: PermissionEntity.APPLICATIONS,
        },
        text: value,
      })),
    ];
  }

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get query(): Record<string, string> {
    return this.application ? { app: this.application.ngacName } : {};
  }

  @Watch("application", { immediate: true })
  fetchPermission(application: PermissionLink | null) {
    if (!application) {
      return;
    }

    this.$store.dispatch("fetchPermissions", application);
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("users.permissionsBrowser.title")
    );

    this.application =
      this.applicationItems.find(
        ({ value }) => value?.ngacName === this.applicationId
      )?.value ?? null;
  }
}
